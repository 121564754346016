const typeOptions = {
  longdate: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  },
  date: {
    day: 'numeric',
    month: 'long',
  },
  time: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  hour: {
    hour: '2-digit',
    hour12: false,
  },
};

function formatDate(date, type = 'date', locale = 'nl-NL') {
  if (!date) {
    return '';
  }

  const localeDate = new Date(date.replace(/ /g, 'T') + 'Z'); // Make it this format: 2019-01-10T08:44:58Z
  const offset = localeDate.getTimezoneOffset();

  const time = localeDate.getTime() + offset * 60 * 1000;

  return new Intl.DateTimeFormat(locale, typeOptions[type]).format(
    new Date(time)
  );
}

export default formatDate;
